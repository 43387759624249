import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import FixedTabs from "@/components/FixedTabs";
import TabOne from "./components/TabOne";
import TabTwo from "./components/TabTwo";
import TabThree from "./components/TabThree";
import "./index.css";

const tabs = [
  { key: "1", title: "先锋领学" },
  { key: "2", title: "跟学成果" },
  { key: "3", title: "往期回顾" },
];

function LearnPage() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const linkId = searchParams.get("link_id");

  const [learnId, setLearnId] = useState(linkId || "");
  const [activeKey, setActiveKey] = useState("1");

  const onTabChange = (key) => {
    setActiveKey(key);
  };

  const handleSwitchDetail = (id) => {
    setActiveKey("1");
    setLearnId(id);
  };

  return (
    <div className="learn_wrap">
      {activeKey === "1" && <TabOne learnId={learnId} />}

      {activeKey === "2" && <TabTwo learnId={learnId} />}

      {activeKey === "3" && <TabThree viewDetail={handleSwitchDetail} />}

      <div className="fixed-controls">
        <FixedTabs activeKey={activeKey} tabs={tabs} onChange={onTabChange} />
      </div>
    </div>
  );
}

export default LearnPage;
