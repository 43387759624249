/**
 * 音频播放组件
 */

import React, { useRef, useState, useEffect } from "react";
import ReactHowler from "react-howler";
import { Image } from "antd-mobile";
import SoundWave from "@/components/SoundWave";
import { convertMillisecondsToText } from "@/utils";
import "./index.css";

function SoundBullet(props) {
  const {
    src,
    currentPlayID,
    playID,
    setCurrentPlayID,
    style,
    size = "large",
    picSrc,
    duration,
  } = props;

  const [playing, setPlaying] = useState(false);

  const audioRef = useRef(null);

  const handleOnPlay = () => {
    setPlaying(true);
  };

  const handleOnEnd = () => {
    setPlaying(false);
  };

  const handleToggle = () => {
    setCurrentPlayID(playID);
    setPlaying((prev) => !prev);
  };

  useEffect(() => {
    if (currentPlayID !== playID && playing) {
      handleOnEnd();
    }
  }, [currentPlayID, playID]);

  return (
    <div className="sound-bullet-out-wrap" style={style}>
      <ReactHowler
        src={src}
        playing={playing}
        ref={audioRef}
        html5={true}
        onPlay={handleOnPlay}
        onEnd={handleOnEnd}
      />
      <div
        className="sound-bullet-wrap"
        style={{ backgroundColor: playing ? "#e3211b" : "#ffffff" }}
        onClick={handleToggle}
      >
        <Image
          src={picSrc}
          width={30}
          height={30}
          fit="cover"
          style={{
            borderRadius: 15,
            marginRight: 6,
          }}
        />
        <SoundWave
          size={size}
          active={playing}
          color={playing ? "#ffffff" : "#5c5c5c"}
        />
        <span
          style={{ fontSize: "0.7rem", color: playing ? "#ffffff" : "#b5b5b5" }}
        >
          &nbsp;
          {duration ? convertMillisecondsToText(duration * 1000) : "00′00″"}
        </span>
      </div>
    </div>
  );
}

export default SoundBullet;
