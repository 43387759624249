/**
 * 学习成果-音频卡片
 */

import React, { useState, useRef, useEffect } from "react";
import { Image } from "antd-mobile";
import IconPause from "@/images/circle-pause.png";
import IconPlay from "@/images/circle-play.png";
import ReactHowler from "react-howler";
import raf from "raf";
import { ClockCircleOutline } from "antd-mobile-icons";
import { convertMillisecondsToText } from "@/utils";
import "./index.css";

function SoundCard(props) {
  const { title, record, playID, currentPlayID, setCurrentPlayID } = props;

  const duration = record.audio_time_len;
  const [playing, setPlaying] = useState(false);
  const [seek, setSeek] = useState(0.0);
  const audioRef = useRef(null);
  const _raf = useRef(null);

  const renderSeekPos = () => {
    setSeek(audioRef.current.seek());
    _raf.current = raf(renderSeekPos);
  };

  useEffect(() => {
    if (playing) {
      renderSeekPos();
    }
    return () => {
      clearRAF();
    };
  }, [playing]);

  useEffect(() => {
    if (currentPlayID !== playID) {
      handleOnEnd();
    }
  }, [currentPlayID, playID]);

  const handleToggle = () => {
    setCurrentPlayID(playID);
    setPlaying((prev) => !prev);
  };

  const handleOnPlay = () => {
    setPlaying(true);
  };

  const handleOnEnd = () => {
    setPlaying(false);
    clearRAF();
  };

  const clearRAF = () => {
    raf.cancel(_raf.current);
  };

  const handleSeekingChange = (e) => {
    setSeek(parseFloat(e.target.value));
    audioRef.current.seek(e.target.value);
  };

  return (
    <>
      <div className={`avatar-wrap ${playing && "avatar-x"}`}>
        <Image
          src={record.avatar_url}
          width={56}
          height={56}
          fit="cover"
          style={{
            borderRadius: 32,
            borderWidth: 2,
            borderColor: "#fff",
          }}
        />
      </div>
      <div className="sound-card-info">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-start",
          }}
        >
          <div>
            <div className="sound-title">{title}</div>
            <div className="sound-date">
              <ClockCircleOutline />
              &nbsp;{record.show_time}
            </div>
          </div>
          <img
            style={{
              width: "2rem",
              height: "2rem",
            }}
            onClick={handleToggle}
            src={playing ? IconPause : IconPlay}
            alt="link"
          />
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            paddingTop: "0.6rem",
          }}
        >
          <input
            className="custom-range"
            style={{ flex: 1 }}
            type="range"
            min="0"
            max={duration ? duration.toFixed(2) : 0}
            step=".01"
            value={seek}
            onChange={handleSeekingChange}
          />
          <span style={{ fontSize: "0.7rem", color: "#b5b5b5" }}>
            &nbsp;
            {duration ? convertMillisecondsToText(duration * 1000) : "00′00″"}
          </span>
        </div>
      </div>

      <ReactHowler
        src={record.audio}
        playing={playing}
        ref={audioRef}
        html5={true}
        onPlay={handleOnPlay}
        onEnd={handleOnEnd}
      />
    </>
  );
}

export default SoundCard;
