import axios from "axios"

const instance = axios.create({
  baseURL: "https://xyzz.kv-you.com/", // 设置baseUrl
  timeout: 60 * 1000, // 可选的超时时间，单位为毫秒
  headers: {
    // 可选的请求头
    // "Content-Type": "application/json",
    // Authorization: "Bearer token",
  },
})

export default instance
