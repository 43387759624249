import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Image } from "antd-mobile";
import logo from "@/images/home/logo.png";
import banner from "@/images/home/banner.png";
import wave from "@/images/home/wave.png";
import bookmark from "@/images/home/bookmark.png";
import speak from "@/images/home/speak.png";
import show from "@/images/home/show.png";
import learn from "@/images/home/learn.png";
import instance from "@/utils/request";
import "./index.css";

function HomePage() {
  const [showExamBtn, setShowExamBtn] = useState(false);
  const [wholeShown, setWholeShown] = useState(false);
  const [gyroTop1, setGyroTop1] = useState("-10%");
  const [gyroLeft1, setGyroLeft1] = useState("-10%");

  const _betaRef = useRef(0);
  const _gammaRef = useRef(0);
  const needAskAuth = useRef(true);

  const navigate = useNavigate();

  useEffect(() => {
    listen();
    instance
      .get("/api/getHomeConfig")
      .then((response) => {
        setShowExamBtn(response.data.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const toggleBookmark = () => {
    if (wholeShown) {
      navigate("/entrance", { replace: true });
    } else {
      setWholeShown((prev) => !prev);
    }
  };

  function listen() {
    if (window.DeviceOrientationEvent) {
      window.addEventListener(
        "deviceorientation",
        function (event) {
          let beta = event.beta,
            gamma = event.gamma;

          if (_betaRef.current === 0 && _gammaRef.current === 0) {
            // 全局还没赋值的时候，先赋值一次
            _betaRef.current = beta;
            _gammaRef.current = gamma;
          }

          // 只用到beta和gamma，手机上下晃动，beta值变化，左右晃动，gamma值变化
          let betaOffset = beta - _betaRef.current;
          let gammaOffset = gamma - _gammaRef.current;

          let betaOffset1, gammaOffset1;

          betaOffset1 = Math.min(betaOffset / 3, 5);
          betaOffset1 = Math.max(betaOffset1, -5);
          gammaOffset1 = Math.min(gammaOffset / 3, 5);
          gammaOffset1 = Math.max(gammaOffset1, -5);

          setGyroTop1(-5 + betaOffset1 + "%");
          setGyroLeft1(-5 + gammaOffset1 + "%");
        },
        false
      );
    } else {
      console.log("你的浏览器不支持陀螺仪");
    }
  }

  // 获取陀螺仪权限
  const auth = () => {
    if (!needAskAuth.current) return;
    needAskAuth.current = false;
    if (typeof DeviceOrientationEvent.requestPermission === "function") {
      DeviceOrientationEvent.requestPermission()
        .then((permissionState) => {
          if (permissionState === "granted") {
            listen();
          } else {
            // handle denied
            alert("拒绝了陀螺仪权限");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      console.log(typeof DeviceOrientationEvent);
    }
  };

  return (
    <div style={{ position: "fixed", left: 0, right: 0, top: 0, bottom: 0 }}>
      <div
        class="gyro-bg bg-1"
        style={{ top: gyroTop1, left: gyroLeft1 }}
      ></div>

      <div className="home_wrap" onClick={auth}>
        <Image className="home-logo" src={logo} width={"6rem"} fit="cover" />

        <div className="bookmark-wrap">
          <Image src={banner} width={"6rem"} fit="cover" />
        </div>

        {showExamBtn ? (
          <Image
            src={bookmark}
            onClick={toggleBookmark}
            width={"6rem"}
            fit="cover"
            className="exam-btn"
            style={{ right: wholeShown && "0.8rem" }}
          />
        ) : null}

        <Image src={wave} className="home-wave" width={"100%"} fit="cover" />

        <div className="btns-wrap">
          <Image
            src={learn}
            fit="cover"
            style={{ flex: 1, margin: "0 1rem" }}
            onClick={() => navigate("/learn", { replace: true })}
          />
          <Image
            src={speak}
            fit="cover"
            style={{ flex: 1, marginTop: "3rem" }}
            onClick={() => navigate("/speak", { replace: true })}
          />
          <Image
            src={show}
            fit="cover"
            style={{ flex: 1, margin: "0 1rem" }}
            onClick={() => navigate("/show", { replace: true })}
          />
        </div>

        <div className="footer">
          <div>制作单位</div>
          <div style={{ paddingBottom: "0.2rem" }}>中共淮安市委组织部</div>
          <div>中共淮安市淮安区委组织部</div>
        </div>
      </div>
    </div>
  );
}

export default HomePage;
