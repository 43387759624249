import React from "react";
import { Image } from "antd-mobile";
import "./index.css";

function ImageTab(props) {
  const { onChange, activeKey, userList } = props;

  return (
    <div className="image-tab-wrap">
      {userList.map((v, index) => (
        <ImageTabItem
          key={index}
          tabKey={index}
          avatarSrc={v.url}
          avatarName={v.name}
          activeKey={activeKey}
          onChange={onChange}
        />
      ))}
    </div>
  );
}

const ImageTabItem = ({
  tabKey,
  activeKey,
  onChange,
  avatarSrc,
  avatarName,
}) => {
  const active = tabKey === activeKey;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        className="image-tab-item-wrap"
        style={{
          transform: active ? "scale(1.1,1.1)" : "scale(1,1)",
        }}
        onClick={() => onChange(tabKey)}
      >
        <Image src={avatarSrc} width={50} height={50} fit="cover" />
      </div>
      <div style={{ color: "#fff" }}>{avatarName}</div>

      {active && <Triangle />}
    </div>
  );
};

const Triangle = () => <div className="triangle"></div>;

export default ImageTab;
