import React from "react";
import PageHeader from "@/components/PageHeader";
import { useNavigate } from "react-router-dom";
import { Button, Image } from "antd-mobile";
import bk1 from "@/images/bm1-lock.png";
import bk2 from "@/images/bm2-lock.png";
import bk3 from "@/images/bm3-lock.png";
import bk4 from "@/images/bm4-lock.png";

import xiTitle from "@/images/xi-title.png";
import "./index.css";

function EntrancePage() {
  const navigate = useNavigate();

  return (
    <div className="entrance-wrap">
      <PageHeader title={"答题赢书签"} />

      <div style={{ display: "flex", paddingTop: "4rem", paddingLeft: "1rem" }}>
        <Image
          src={bk1}
          fit="cover"
          style={{
            flex: 1,
            margin: "0 0.2rem",
            boxShadow: "1px 1px 4px rgba(0, 0, 0, 0.2)",
            borderRadius: 4,
          }}
        />
        <Image
          src={bk2}
          fit="cover"
          style={{
            flex: 1,
            margin: "0 0.2rem",
            boxShadow: "1px 1px 4px rgba(0, 0, 0, 0.2)",
            borderRadius: 4,
          }}
        />
        <Image
          src={bk3}
          fit="cover"
          style={{
            flex: 1,
            margin: "0 0.2rem",
            boxShadow: "1px 1px 4px rgba(0, 0, 0, 0.2)",
            borderRadius: 4,
          }}
        />
        <Image
          src={bk4}
          fit="cover"
          style={{
            flex: 1,
            margin: "0 0.2rem",
            boxShadow: "1px 1px 4px rgba(0, 0, 0, 0.2)",
            borderRadius: 4,
          }}
        />
        <Image
          src={xiTitle}
          fit="cover"
          style={{ width: 60, height: 120, marginLeft: 10, marginRight: 10 }}
        />
      </div>
      <div className="red-line"></div>
      <div className="red-text">
        <ul>
          <li>“习语谆谆”主题教育答题赢书签活动定期开放。</li>
          <li>每期共有5道题，包括单项或多项选择题，答案提交后不能更改。</li>
          <li>
            当期答题全部正确，即可随机点亮一枚电子书签。成功点亮4枚电子书签，即可至线下指定地点兑换实体书签一套（实体书签兑换日期及地点请关注“淮安先锋”公众号）。
          </li>
        </ul>
      </div>

      <div className="fixed-controls">
        <Button
          block
          shape="rounded"
          style={{
            fontFamily: "FZXBSJW",
            width: "8rem",
            boxShadow: "1px 1px 4px rgba(0, 0, 0, 0.2)",
          }}
          onClick={() => navigate("/bookmark", { replace: true })}
        >
          去答题
        </Button>
      </div>
    </div>
  );
}

export default EntrancePage;
