import { useEffect, useRef, useState } from "react";
import { convertMillisecondsToMS } from "@/utils";

function Timer() {
  const requestRef = useRef();
  const startTimeRef = useRef();
  const [currentTime, setCurrentTime] = useState(0);

  const updateTime = () => {
    setCurrentTime(performance.now() - startTimeRef.current);
    requestRef.current = requestAnimationFrame(updateTime);
  };

  useEffect(() => {
    startTimeRef.current = performance.now();
    requestRef.current = requestAnimationFrame(updateTime);

    return () => {
      cancelAnimationFrame(requestRef.current);
    };
  }, []);

  return <div>{convertMillisecondsToMS(currentTime.toFixed(0))}</div>;
}

export default Timer;
