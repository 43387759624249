import React, { useState, useEffect, useRef, useContext } from "react";
import { useSprings, animated, to as interpolate } from "@react-spring/web";
import { useDrag } from "react-use-gesture";
import HeaderBanner from "../HeaderBanner";
import SoundCard from "@/components/SoundCard";
import SoundBullet from "@/components/SoundBullet";
import PageHeader from "@/components/PageHeader";
import raf from "raf";
import instance from "@/utils/request";
import { getRandomOffsetX } from "@/utils";
import { AppContext } from "@/context";
import "./index.css";

const PAGE_SIZE = 10;

const to = (i) => ({
  x: i * 4,
  y: i * -4,
  scale: 1,
  delay: i * 100,
});
const from = (_i) => ({ x: 0, scale: 1.5 });
// This is being used down there in the view, it interpolates rotation and scale into a css transform
const trans = (r, s) =>
  `perspective(1500px) rotateX(30deg) rotateY(${
    r / 10
  }deg) rotateZ(${r}deg) scale(${s})`;

const windowWidth =
  window.innerWidth ||
  document.documentElement.clientWidth ||
  document.body.clientWidth;

function TabTwo(props) {
  const { learnId } = props;
  const [currentPlayID, setCurrentPlayID] = useState(null);
  const [detail, setDetail] = useState({});
  const [cards, setCards] = useState([]);
  const _raf = useRef(null);
  const learnIdRef = useRef("");
  const { userCode } = useContext(AppContext);
  const pageIndexRef = useRef(1);

  // 弹幕屏幕
  const [danmakuList, setDanmakuList] = useState([]);

  // 移动弹幕
  const moveDanmaku = (min) => {
    setDanmakuList((prevList) => {
      return prevList.map((item) => {
        let offsetX = item.offsetX + 1;
        if (offsetX >= windowWidth - min) {
          getDanmu(pageIndexRef.current);
        }
        return {
          ...item,
          offsetX,
        };
      });
    });
    _raf.current = raf(() => moveDanmaku(min));
  };

  const getDanmu = async (pageIndex, _learnId) => {
    raf.cancel(_raf.current);
    const response = await instance.get("/api/getLearnFollowsByLearnId", {
      params: {
        learnId: _learnId || learnIdRef.current,
        pageSize: PAGE_SIZE,
        pageIndex,
      },
    });
    const danmuList = response.data.data.map((v) => {
      return { ...v, offsetX: getRandomOffsetX() };
    });
    const minOffsetX = Math.min.apply(
      null,
      danmuList.map((v) => v.offsetX)
    );
    const hasMore = response.data.data.length === PAGE_SIZE;
    pageIndexRef.current = hasMore ? pageIndexRef.current + 1 : 1;
    setDanmakuList(danmuList);
    moveDanmaku(minOffsetX);
  };

  const getMyAudio = async (_learnId, _userId) => {
    const response = await instance.get("/api/getMyLearnFollows", {
      params: {
        learnId: _learnId,
        userId: _userId,
      },
    });
    if (response.data.data) {
      response.data.data.reverse();
      setCards(response.data.data);
    }
  };

  const getDetail = async (id) => {
    const response = await instance.get("/api/getLearnById", {
      params: {
        id,
      },
    });
    setDetail(response.data.data);
    learnIdRef.current = response.data.data.id;
    getDanmu(1, response.data.data.id);
    getMyAudio(response.data.data.id, userCode);
  };

  useEffect(() => {
    getDetail(learnId);
  }, []);

  const [gone] = useState(() => new Set());
  const [springProps, api] = useSprings(cards.length, (i) => ({
    ...to(i),
    from: from(i),
  })); // Create a bunch of springs using the helpers above
  // Create a gesture, we're interested in down-state, delta (current-pos - click-pos), direction and velocity
  const bind = useDrag(
    ({
      args: [index],
      down,
      movement: [mx],
      direction: [xDir],
      velocity,
      cancel,
    }) => {
      if (xDir > 0) {
        cancel(); // 取消拖动操作
        return;
      }
      const trigger = velocity > 0.2; // If you flick hard enough it should trigger the card to fly out
      const dir = xDir < 0 ? -1 : 1; // Direction should either point left or right
      if (!down && trigger) gone.add(index); // If button/finger's up and trigger velocity is reached, we flag the card ready to fly out
      api.start((i) => {
        if (index !== i) return; // We're only interested in changing spring-data for the current spring
        const isGone = gone.has(index);
        const x = isGone ? (200 + window.innerWidth) * dir : down ? mx : 0; // When a card is gone it flys out left or right, otherwise goes back to zero
        const scale = down ? 1.1 : 1;
        return {
          x,
          scale,
          delay: undefined,
          config: { friction: 50, tension: down ? 800 : isGone ? 200 : 500 },
        };
      });
      if (!down && gone.size === cards.length)
        setTimeout(() => {
          gone.clear();
          api.start((i) => to(i));
        }, 600);
    }
  );

  useEffect(() => {
    return () => raf.cancel(_raf.current);
  }, []);

  // 渲染弹幕元素
  const renderDanmaku = (item, index) => {
    const style = {
      position: "absolute",
      top: index < 7 ? index * 46 + "px" : (index - 6) * 46 + "px",
      right: index < 7 ? item.offsetX + "px" : item.offsetX - 150 + "px",
    };

    return (
      <SoundBullet
        key={item.id}
        style={style}
        size={item.size}
        picSrc={item.avatar_url}
        playID={`bullet_sound_${index}`}
        currentPlayID={currentPlayID}
        setCurrentPlayID={setCurrentPlayID}
        src={item.audio}
        duration={item.audio_time_len}
      />
    );
  };

  return (
    <div className="learn_tab2_wrap">
      <PageHeader
        title={"学金句"}
        subTitle={detail.publish_time && detail.publish_time.substring(0, 10)}
      />
      <HeaderBanner
        detailInfo={detail}
        playID={"banner_sound"}
        currentPlayID={currentPlayID}
        setCurrentPlayID={setCurrentPlayID}
      />
      <div className="bullets_wrap">{danmakuList.map(renderDanmaku)}</div>
      <div className="card_box_wrap">
        {springProps.map(({ x, y, scale }, i) => (
          <animated.div className={"deck"} key={i} style={{ x, y }}>
            <animated.div
              {...bind(i)}
              style={{
                transform: interpolate([scale], trans),
                padding: "0.6rem",
                boxSizing: "border-box",
                display: "flex",
                alignItems: "center",
              }}
            >
              <SoundCard
                record={cards[i] || {}}
                title={detail.title}
                playID={`card_sound_${i}`}
                currentPlayID={currentPlayID}
                setCurrentPlayID={setCurrentPlayID}
              />
            </animated.div>
          </animated.div>
        ))}
      </div>
    </div>
  );
}

export default TabTwo;
