import React, { useState, useContext, useEffect, useRef } from "react";
import PageHeader from "@/components/PageHeader";
import FixedTabs from "@/components/FixedTabs";
import { useNavigate } from "react-router-dom";
import ExamCard from "./ExamCard";
import Bookcase from "./Bookcase";
import {
  AutoCenter,
  SpinLoading,
  Toast,
  InfiniteScroll,
  List,
  Button,
} from "antd-mobile";
import Slider from "react-slick";
import instance from "@/utils/request";
import { AppContext } from "@/context";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./index.css";

const tabs = [
  { key: "1", title: "答题" },
  { key: "2", title: "我的书签" },
];

const PAGE_SIZE = 10;

function BookmarkPage() {
  const { userCode } = useContext(AppContext);
  const [activeKey, setActiveKey] = useState("1");
  const [loading, setLoading] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [canAnswer, setCanAnswer] = useState(false);
  const [answers, setAnswers] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [data, setData] = useState([]);
  const [pageIndex, setPageIndex] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const navigate = useNavigate();

  const getQsList = async (page) => {
    setLoading(true);
    const response = await instance.get("/api/getQuestionTermList", {
      params: {
        pageSize: PAGE_SIZE,
        pageIndex: page,
      },
    });

    const append = response.data.data;
    setPageIndex(page);
    setData((prev) => prev.concat(append));
    setHasMore(append.length === PAGE_SIZE);
    setLoading(false);
  };

  const getQs = async (_term) => {
    setLoading(true);
    const res = await instance.get("/api/getQuestionsByTerm", {
      params: {
        term: _term,
        userId: userCode,
      },
    });

    const qsArr = res.data.data.questions;
    setQuestions(qsArr || []);
    setCanAnswer(res.data.data.canAnswer);
    if (res.data.data.canAnswer === 1) {
      const tempArr = qsArr.map((v) => {
        return {
          user_id: userCode,
          term: _term,
          question_id: v.id,
          user_answer_index: "",
        };
      });
      setAnswers(tempArr);
    }
    setLoading(false);
  };

  useEffect(() => {
    getQsList(1);
  }, []);

  const onTabChange = (key) => {
    setSelectedRow(null);
    setActiveKey(key);
  };

  const settings = {
    infinite: false,
    centerMode: true,
    centerPadding: "36px",
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const handleSubmit = async () => {
    const res = await instance.post("/api/addAnswerRecord", answers);
    if (res) {
      Toast.show({
        icon: "success",
        content: res.data.data ? "全部答对！" : "回答有误，请重新答题",
      });
      if (res.data.data) {
        getQs(selectedRow.id);
      }
    }
  };

  const handleModifyAnswers = (qid, indexStrings) => {
    const arr = answers.concat();
    arr.find((v) => v.question_id === qid).user_answer_index = indexStrings;
    setAnswers(arr);
  };

  const viewDetail = (qsn) => {
    if (qsn.status === 0) return;
    getQs(qsn.id);
    setSelectedRow(qsn);
  };

  const renderContent = () => {
    if (selectedRow) {
      return (
        <div className="content">
          <div className="card-wrapper-all">
            <Slider {...settings}>
              {questions.map((qs, index) => (
                <ExamCard
                  key={qs.id}
                  canAnswer={canAnswer}
                  data={qs}
                  total={questions.length}
                  index={index + 1}
                  onSubmit={handleSubmit}
                  onModify={handleModifyAnswers}
                />
              ))}
            </Slider>
          </div>
        </div>
      );
    }
    return (
      <div className="bookmark_list">
        <List>
          {data.map((qsn) => (
            <List.Item
              arrow={false}
              key={qsn.id}
              onClick={() => viewDetail(qsn)}
            >
              <div style={{ fontSize: 15, fontWeight: "bold" }}>{qsn.name}</div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div style={{ color: "gray", fontSize: 14 }}>
                  {qsn.publish_time && qsn.publish_time.substring(0, 10)}
                </div>
                <Button
                  size="small"
                  block
                  shape="rounded"
                  color={qsn.status === 1 ? "primary" : "default"}
                  style={{ fontFamily: "FZXBSJW", width: "5rem", fontSize: 13 }}
                >
                  {qsn.status === 1 ? "开始答题" : "答题结束"}
                </Button>
              </div>
            </List.Item>
          ))}
        </List>
        <InfiniteScroll
          loadMore={() => getQsList(hasMore ? pageIndex + 1 : 1)}
          hasMore={hasMore}
        />
        <div className="bottom-cover"></div>
      </div>
    );
  };

  const handleBack = () => {
    if (selectedRow) {
      setSelectedRow(null);
    } else {
      navigate("/entrance", { replace: true });
    }
  };

  return (
    <div className="bookmark_wrap">
      <PageHeader title={"答题赢书签"} onBack={handleBack} />

      {activeKey === "1" && renderContent()}

      {activeKey === "2" && <Bookcase userCode={userCode} />}

      <div className="fixed-controls">
        <FixedTabs activeKey={activeKey} tabs={tabs} onChange={onTabChange} />
      </div>
    </div>
  );
}

export default BookmarkPage;
