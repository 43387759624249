/**
 * 自定义Icon组件
 */

import React from "react";
import IconMic from "@/images/icon-micro.png";
import "./index.css";

export default function CusIcons(props) {
  const { label, style, onClick } = props;

  return (
    <div className="icon-wrap" style={style} onClick={onClick}>
      <img src={IconMic} alt="nth" />
      {label && <div className="icon-label">{label}</div>}
    </div>
  );
}
