/**
 * 声波组件
 */

import React from "react";
import "./index.css";

function SoundWave(props) {
  const { size = "middle", active = false, color = "#5c5c5c" } = props;

  const sizeToNumber = {
    small: 6,
    middle: 12,
    large: 24,
  };

  const tempArr = new Array(sizeToNumber[size]).fill(1);

  return (
    <div className={active ? "sound-wave-wrap" : "sound-wave-static-wrap"}>
      <ul>
        {tempArr.map((v, index) => (
          <p key={index} style={{ backgroundColor: color }}></p>
        ))}
      </ul>
    </div>
  );
}

export default SoundWave;
