import React, { useState, useEffect } from "react";
import { Ellipsis, List, InfiniteScroll, Modal, Avatar } from "antd-mobile";
import instance from "@/utils/request";
import ShowCard from "./ShowCard";
import ding from "@/images/ding.png";
import AddInfoModal from "./AddInfoModal";
import IconEdit from "@/images/icon-edit.svg";
import "./index.css";

const PAGE_SIZE = 10;

function ShowSelfList(props) {
  const { userCode } = props;
  const [data, setData] = useState([]);
  const [addVisible, setAddVisible] = useState(false);
  const [visible, setVisible] = useState(false);
  const [pageIndex, setPageIndex] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [record, setRecord] = useState(null);

  const handleWrite = () => {
    setAddVisible(true);
  };

  const getList = async (page) => {
    const response = await instance.get("/api/getDemeanourList", {
      params: {
        type: "展我风采",
        pageSize: PAGE_SIZE,
        pageIndex: page,
        userId: userCode,
      },
    });
    const append = response.data.data;
    setData((prev) => (page === 1 ? append : prev.concat(append)));
    setHasMore(append.length === PAGE_SIZE);
    setPageIndex(page + 1);
  };

  useEffect(() => {
    getList(1);
  }, []);

  const loadMore = () => {
    getList(pageIndex);
  };

  const viewDetail = (user) => {
    setRecord({
      ...user,
      name: user.user_name,
      pic: user.avatar_url,
    });
    setVisible(true);
  };

  const renderDetail = () => {
    return (
      <ShowCard star={true} data={record} cardType={"3"} userCode={userCode} />
    );
  };

  const handleAddCallback = (append) => {
    const arr = data.concat();
    arr.unshift(append);
    setData(arr);
  };

  return (
    <>
      <div className="learn_tab3_wrap show_self_list_wrap">
        <List>
          {data.map((user) => (
            <List.Item
              arrow={false}
              key={user.id}
              style={{ position: "relative" }}
              onClick={() => viewDetail(user)}
              prefix={<Avatar src={user.avatar_url} />}
            >
              <div className="list_item_wrap">
                <div className="list_item_name">
                  {user.user_name || "微信昵称"}
                </div>
                <div className="list_item_row">
                  <div
                    className="list_item_id"
                    style={{ wordBreak: "break-word" }}
                  >
                    <Ellipsis
                      direction="end"
                      content={user.deed}
                      style={{ color: "#a1a1a1", paddingRight: "1rem" }}
                    />
                  </div>
                  <div className="list_item_date">{user.like_num}个赞</div>
                </div>
              </div>
              {user.is_selected === 1 && (
                <img
                  src={ding}
                  alt="ding"
                  style={{
                    width: "4.2rem",
                    position: "absolute",
                    right: 0,
                    top: 0,
                  }}
                />
              )}
            </List.Item>
          ))}
        </List>
        <div className="edit_wrap" onClick={handleWrite}>
          <img
            src={IconEdit}
            alt="我的风采"
            style={{
              width: "1.4rem",
              height: "1.4rem",
              paddingBottom: "0.3rem",
            }}
          />
          <span
            style={{
              color: "#fff",
              fontWeight: "bold",
              fontSize: 10,
              fontFamily: "FZXBSJW",
            }}
          >
            我的风采
          </span>
        </div>
        <InfiniteScroll loadMore={loadMore} hasMore={hasMore} />
        <div className="bottom-cover"></div>

        {/* 详情弹窗 */}
        <Modal
          header={null}
          bodyStyle={{ padding: 0 }}
          visible={visible}
          content={renderDetail()}
          closeOnMaskClick
          destroyOnClose
          getContainer={document.querySelector(".show_self_list_wrap")}
          onClose={() => {
            getList(1);
            setVisible(false);
          }}
        />
      </div>
      <AddInfoModal
        visible={addVisible}
        setVisible={setAddVisible}
        userCode={userCode}
        onSuccess={handleAddCallback}
      />
    </>
  );
}

export default ShowSelfList;
