import React, { useState, useEffect } from "react";
import { Image, Modal, Divider, InfiniteScroll } from "antd-mobile";
import instance from "@/utils/request";
import Slider from "react-slick";
import "./index.css";

const PAGE_SIZE = 12;

function Multiple(props) {
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [pageIndex, setPageIndex] = useState(1);

  const getList = async (page) => {
    setLoading(true);
    const response = await instance.get("/api/getHistorySpeaks", {
      params: {
        type: "先锋说",
        pageSize: PAGE_SIZE,
        pageIndex: page,
      },
    });
    const append = response.data.data;
    setList((prev) => prev.concat(append));
    setHasMore(append.length === PAGE_SIZE);
    setPageIndex(page + 1);
    setLoading(false);
  };

  const loadMore = () => {
    getList(pageIndex);
  };

  const renderDetail = (data) => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      >
        <Image
          src={data.pic}
          width={50}
          height={50}
          fit="cover"
          style={{
            borderRadius: 25,
          }}
        />
        <div
          style={{
            fontSize: "1.1rem",
            marginTop: "1rem",
          }}
        >
          {data.name}
        </div>
        <Divider style={{ width: "100%" }} />
        {data.picList && data.picList.length > 0 && (
          <div>
            <Slider
              autoplay={true}
              dots={true}
              arrows={false}
              infinite={false}
              className={"wcnm"}
            >
              {data.picList.map((item) => (
                <img key={item.id} src={item.url} alt="mar" />
              ))}
            </Slider>
          </div>
        )}
        <div
          style={{
            textAlign: "center",
            paddingBottom: "1rem",
            paddingTop: "1rem",
            color: "#a1a1a1",
            fontSize: 13,
          }}
        >
          {data.name} {data.intro}
        </div>
        <div
          style={{
            fontSize: "1rem",
            lineHeight: "1.6rem",
            paddingLeft: "0.6rem",
            paddingRight: "0.6rem",
            height: "12rem",
            maxHeight: "12rem",
            textIndent: "2rem",
          }}
        >
          {data.content}
        </div>
      </div>
    );
  };

  const handleViewDetail = (detail) => {
    Modal.show({
      content: renderDetail(detail),
      showCloseButton: true,
      closeOnMaskClick: true,
    });
  };

  useEffect(() => {
    getList(1);
  }, []);

  return (
    <div className="muli-wrap">
      <div
        className="muli-wrap2"
        style={{ maxHeight: "calc(100vh - 45px)", overflowY: "scroll" }}
      >
        {list.map((item) => {
          return (
            <div className="image-muli-item">
              <div
                className="image-muli-inner-item"
                onClick={() => handleViewDetail(item)}
              >
                <Image src={item.pic} width={60} height={60} fit="cover" />
              </div>
              <div style={{ color: "#fff", marginTop: 4 }}>{item.name}</div>
            </div>
          );
        })}
      </div>
      <InfiniteScroll loadMore={loadMore} hasMore={hasMore}>
        {""}
      </InfiniteScroll>
    </div>
  );
}

export default Multiple;
