/**
 * 头部导航栏组件
 */

import React from "react";
import { NavBar } from "antd-mobile";
import { useNavigate } from "react-router-dom";
import IconHome from "@/images/back.png";
import "./index.css";

function PageHeader(props) {
  const { title, subTitle, extra, leftBack, onBack } = props;
  const navigate = useNavigate();
  const back = () => navigate("/", { replace: true });

  return (
    <NavBar
      onBack={onBack || back}
      backArrow={false}
      style={{ backgroundColor: "#E3221C" }}
      right={extra}
      back={
        !leftBack ? (
          <img
            src={IconHome}
            alt="主页"
            style={{ width: "1.4rem", height: "1.4rem" }}
          />
        ) : (
          <span style={{ fontFamily: "FZXBSJW", color: "#fff" }}>返回</span>
        )
      }
    >
      <div className="header-title">{title}</div>
      <div className="header-date">{subTitle}</div>
    </NavBar>
  );
}

export default PageHeader;
