import React, { useState, useEffect } from "react";
import instance from "@/utils/request";
import bm1 from "@/images/bk1.png";
import bm2 from "@/images/bk2.png";
import bm3 from "@/images/bk3.png";
import bm4 from "@/images/bk4.png";
import bmLock1 from "@/images/bm1-lock.png";
import bmLock2 from "@/images/bm2-lock.png";
import bmLock3 from "@/images/bm3-lock.png";
import bmLock4 from "@/images/bm4-lock.png";

function Bookcase({ userCode }) {
  const [isLock, setIsLock] = useState([]);
  const [loading, setLoading] = useState(false);
  const [rank, setRank] = useState(null);

  useEffect(() => {
    getList();
    getRank();
  }, []);

  const getList = async () => {
    setLoading(true);
    const res = await instance.get("/api/checkBookMark", {
      params: {
        userId: userCode,
      },
    });
    if (res) {
      setIsLock(res.data.data);
    }
    setLoading(false);
  };

  const getRank = async () => {
    setLoading(true);
    const res = await instance.get("/api/getUserBookRemarkRank", {
      params: {
        userId: userCode,
      },
    });
    if (res) {
      setRank(res.data.data.rank);
    }
    setLoading(false);
  };

  return (
    <div className="bookcase-wrap">
      <div className="bookcase-inner-wrap">
        <img
          src={Number(isLock[0]) ? bm1 : bmLock1}
          alt="bc"
          className="bookcase-item"
        />
        <img
          src={Number(isLock[1]) ? bm2 : bmLock2}
          alt="bc"
          className="bookcase-item"
        />
        <img
          src={Number(isLock[2]) ? bm3 : bmLock3}
          alt="bc"
          className="bookcase-item"
        />
        <img
          src={Number(isLock[3]) ? bm4 : bmLock4}
          alt="bc"
          className="bookcase-item"
        />
        <div className="rank-wrap">
          {rank ? (
            <>
              我是第
              {String(rank)
                .split("")
                .map((text, index) => (
                  <span key={index} className="magic-block">
                    {text}
                  </span>
                ))}
              位赢得书签的人
            </>
          ) : (
            "参与答题赢得书签"
          )}
        </div>
      </div>
    </div>
  );
}

export default Bookcase;
