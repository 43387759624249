import React, { useState, useContext } from "react";
import { Modal, Button, TextArea, Toast, Selector } from "antd-mobile";
import instance from "@/utils/request";
import { AppContext } from "@/context";
import BgGreen from "@/images/paper-green.jpg";
import BgPurple from "@/images/paper-purple.jpg";
import BgCyan from "@/images/paper-cyan.jpg";
import BgPink from "@/images/paper-pink.jpg";
import BgBlue from "@/images/paper-blue.jpg";
import BgYellow from "@/images/paper-yellow.jpg";

const selectOptions = [
  {
    label: (
      <img src={BgBlue} alt="bg" style={{ width: "100%", height: "100%" }} />
    ),
    value: "1",
  },
  {
    label: (
      <img src={BgPurple} alt="bg" style={{ width: "100%", height: "100%" }} />
    ),
    value: "2",
  },
  {
    label: (
      <img src={BgCyan} alt="bg" style={{ width: "100%", height: "100%" }} />
    ),
    value: "3",
  },
  {
    label: (
      <img src={BgGreen} alt="bg" style={{ width: "100%", height: "100%" }} />
    ),
    value: "4",
  },
  {
    label: (
      <img src={BgPink} alt="bg" style={{ width: "100%", height: "100%" }} />
    ),
    value: "5",
  },
  {
    label: (
      <img
        src={BgYellow}
        alt="bg"
        style={{
          width: "100%",
          height: "100%",
        }}
      />
    ),
    value: "6",
  },
];

export default function AddWordsModal(props) {
  const { visible, onClose } = props;
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState("");
  const [cardBgType, setCardBgType] = useState(["1"]);
  const [step, setStep] = useState(1);
  const { userCode } = useContext(AppContext);

  const handleSubmit = async (v, type) => {
    const params = {
      user_id: userCode,
      content: v,
      label_id: type,
    };
    setLoading(true);
    const res = await instance.post("/api/addSpeak", params);
    if (res.data.code === 0) {
      onClose(res.data.data);
      setLoading(false);
      Toast.show({
        icon: "success",
        content: "提交成功",
      });
    }
  };

  const renderSelect = () => {
    return (
      <div>
        <Selector
          options={selectOptions}
          defaultValue={cardBgType}
          onChange={(arr) => setCardBgType(arr)}
        />
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "1rem",
          }}
        >
          <Button
            block
            shape="rounded"
            color="primary"
            fill="outline"
            size="small"
            style={{
              fontFamily: "FZXBSJW",
              width: "6rem",
              marginRight: "1rem",
            }}
            onClick={() => onClose()}
          >
            返回
          </Button>
          <Button
            block
            shape="rounded"
            color="primary"
            size="small"
            style={{ fontFamily: "FZXBSJW", width: "6rem" }}
            onClick={() => setStep(2)}
          >
            下一步
          </Button>
        </div>
      </div>
    );
  };

  const renderInput = () => {
    return (
      <>
        <div className="my_textarea_wrap">
          <div style={{ backgroundColor: "#f4f4f4", padding: 6 }}>
            <TextArea
              style={{ backgroundColor: "#f4f4f4" }}
              placeholder="输入你想说的话"
              rows={4}
              autoSize
              showCount
              maxLength={50}
              onChange={(val) => {
                setValue(val);
              }}
            />
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "1rem",
            }}
          >
            <Button
              block
              shape="rounded"
              color="primary"
              fill="outline"
              size="small"
              style={{
                fontFamily: "FZXBSJW",
                width: "6rem",
                marginRight: "1rem",
              }}
              onClick={() => setStep(1)}
            >
              上一步
            </Button>
            <Button
              block
              shape="rounded"
              color="primary"
              size="small"
              style={{ fontFamily: "FZXBSJW", width: "6rem" }}
              onClick={() => handleSubmit(value, cardBgType[0])}
            >
              提交
            </Button>
          </div>
        </div>
      </>
    );
  };

  return (
    <Modal
      title={
        step === 1 ? (
          <span style={{ fontFamily: "FZXBSJW", fontSize: "1rem" }}>
            选择便签
          </span>
        ) : (
          "输入感悟"
        )
      }
      afterClose={() => setStep(1)}
      visible={visible}
      content={step === 1 ? renderSelect() : renderInput()}
      closeOnMaskClick={false}
      destroyOnClose
      getContainer={document.querySelector(".my_panel_wrap")}
      onClose={() => {
        onClose();
      }}
    />
  );
}
