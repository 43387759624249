/**
 * 音频播放组件
 */

import React, { useRef, useState, useEffect } from "react";
import ReactHowler from "react-howler";
import raf from "raf";
import IconSoundStop from "@/images/sound-stop.png";
import IconSoundPlay from "@/images/sound-play.png";
import { convertMillisecondsToMS } from "@/utils";
import "./index.css";

function SoundPlayer(props) {
  const { src, onPlay, playID, currentPlayID, setCurrentPlayID } = props;

  const [playing, setPlaying] = useState(false);
  const [duration, setDuration] = useState(NaN);
  const [seek, setSeek] = useState(0.0);

  const audioRef = useRef(null);
  const _raf = useRef(null);

  const renderSeekPos = () => {
    setSeek(audioRef.current.seek());
    _raf.current = raf(renderSeekPos);
  };

  useEffect(() => {
    if (playing) {
      renderSeekPos();
    }
    onPlay();
    return () => clearRAF();
  }, [playing]);

  useEffect(() => {
    if (currentPlayID !== playID) {
      handleOnEnd();
    }
  }, [currentPlayID, playID]);

  const handleOnLoad = () => {
    setDuration(audioRef.current.duration());
  };

  const handleOnPlay = () => {
    setPlaying(true);
  };

  const handleOnEnd = () => {
    setPlaying(false);
    clearRAF();
  };

  const clearRAF = () => {
    raf.cancel(_raf.current);
  };

  const handleToggle = () => {
    setCurrentPlayID(playID);
    setPlaying((prev) => !prev);
  };

  const handleSeekingChange = (e) => {
    setSeek(parseFloat(e.target.value));
    audioRef.current.seek(e.target.value);
  };

  return (
    <div className="sound-wrap">
      <ReactHowler
        src={src}
        playing={playing}
        ref={audioRef}
        html5={true}
        onLoad={handleOnLoad}
        onPlay={handleOnPlay}
        onEnd={handleOnEnd}
      />
      {playing ? (
        <img
          src={IconSoundStop}
          style={{ width: "1.6rem" }}
          alt="IconSoundPlay"
          onClick={handleToggle}
        />
      ) : (
        <img
          src={IconSoundPlay}
          style={{ width: "1.6rem" }}
          alt="IconSoundPlay"
          onClick={handleToggle}
        />
      )}
      <span className="slider-container">
        <span>{convertMillisecondsToMS(seek * 1000)}&nbsp;</span>
        <input
          className="custom-range"
          style={{ flex: 1 }}
          type="range"
          min="0"
          max={duration ? duration.toFixed(2) : 0}
          step=".01"
          value={seek}
          onChange={handleSeekingChange}
        />
        <span>
          &nbsp;{duration ? convertMillisecondsToMS(duration * 1000) : "0:00"}
        </span>
      </span>
    </div>
  );
}

export default SoundPlayer;
