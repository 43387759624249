import React, { useState, useEffect, useContext } from "react";
import { Popup, SpinLoading, Toast } from "antd-mobile";
import { ReactMic } from "react-mic";
import ReactHowler from "react-howler";
import CusIcons from "@/components/CusIcons";
import IconMic from "@/images/icon-micro.png";
import Timer from "@/components/Timer";
import SoundWave from "@/components/SoundWave";
import IconPause from "@/images/circle-pause.png";
import IconPlay from "@/images/circle-play.png";
import IconStop from "@/images/circle-stop.png";
import HeaderBanner from "../HeaderBanner";
import instance from "@/utils/request";
import { AppContext } from "@/context";
import PageHeader from "@/components/PageHeader";
import "./index.css";

function TabOne(props) {
  const { learnId } = props;
  const [popVisible, setPopVisible] = useState(false);
  const [record, setRecord] = useState(false);
  const [loading, setLoading] = useState(true);
  const [playing, setPlaying] = useState(false);
  const [blob, setBlob] = useState("");
  const [detail, setDetail] = useState({});
  const [ready, setReady] = useState(true);
  const [currentPlayID, setCurrentPlayID] = useState(null);
  const { userCode } = useContext(AppContext);

  const getDetail = async (id) => {
    const response = await instance.get("/api/getLearnById", {
      params: {
        id,
      },
    });
    setDetail(response.data.data || {});
  };

  useEffect(() => {
    getDetail(learnId);
    // 触发一次异步更新，不然录音器无法初始化
    setLoading(false);
  }, []);

  const startRecording = () => {
    setCurrentPlayID(null);
    setRecord(true);
  };

  // const onData = (recordedBlob) => {
  //   console.log("chunk of real-time data is: ", recordedBlob);
  // };

  const onStop = (recordedBlob) => {
    console.log("recordedBlob is: ", recordedBlob, recordedBlob.blob.type);
    setBlob(recordedBlob);
  };

  const handleReLearn = (flag) => {
    if (flag) return;
    setRecord(true);
  };

  const handleToggle = (flag) => {
    if (flag) {
      setRecord(false);
    } else {
      setPlaying((prev) => !prev);
    }
  };

  // 提交录音
  const handleSubmit = async () => {
    setLoading(true);
    const formData = new FormData();
    let fileName = `audio-xi-${Date.now()}.mp3`;
    formData.append("fileToUpload", blob.blob, fileName);
    instance
      .post("/api/file/doUploadAudio", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        const audioTimeLen = Math.round(
          (blob.stopTime - blob.startTime) / 1000
        );
        console.log("/api/addLearnFollow的请求参数", {
          learnId: detail.id,
          userId: userCode,
          audio: response.data.data.src,
          audioTimeLen: audioTimeLen,
        });
        instance
          .get("/api/addLearnFollow", {
            params: {
              learnId: detail.id,
              userId: userCode,
              audio: response.data.data.src,
              audioTimeLen: audioTimeLen,
            },
          })
          .then((res) => {
            Toast.show({
              icon: "success",
              content: "提交成功",
            });
            setPopVisible(false);
            setLoading(false);
          });
      })
      .catch((error) => {
        console.error(error);
        setPopVisible(false);
        setLoading(false);
      });
  };

  const handleBeforePlay = () => {
    setPopVisible(false);
    setRecord(false);
  };

  return (
    <div className="learn_tab1_wrap">
      <PageHeader
        title={"学金句-先锋领学"}
        subTitle={detail.publish_time && detail.publish_time.substring(0, 10)}
      />
      <HeaderBanner
        detailInfo={detail}
        playID={"banner_sound"}
        currentPlayID={currentPlayID}
        setCurrentPlayID={setCurrentPlayID}
        beforePlay={handleBeforePlay}
      />

      <div
        className="articles"
        onClick={() => {
          setPopVisible(false);
          setRecord(false);
          setReady(true);
        }}
      >
        <div className="article-title">{detail.title}</div>
        <div className="article-words">{detail.content}</div>
        <div
          style={{
            fontSize: "1.3rem",
            lineHeight: "2.2rem",
            marginTop: "2rem",
          }}
        >
          {detail.source}
        </div>
        <div style={{ height: "4rem" }}></div>
      </div>

      <div className="fixed-controls">
        <div
          className="edit_wrap"
          onClick={() => {
            setCurrentPlayID(null);
            setTimeout(() => {
              setPopVisible(true);
            }, 100);
          }}
        >
          <img
            src={IconMic}
            alt="我要跟学"
            style={{
              width: "1.5rem",
              height: "1.5rem",
              paddingBottom: "0.3rem",
            }}
          />
          <span
            style={{
              color: "#fff",
              fontWeight: "bold",
              fontSize: 10,
              fontFamily: "FZXBSJW",
            }}
          >
            我要跟学
          </span>
        </div>
      </div>

      {/* 自己的录音音频 */}
      {blob && (
        <ReactHowler
          src={blob.blobURL}
          playing={playing}
          html5={true}
          onEnd={() => setPlaying(false)}
          loop={false}
        />
      )}

      <Popup
        visible={popVisible}
        forceRender={true}
        getContainer={document.querySelector(".learn_wrap")}
        onClose={() => {
          setPopVisible(false);
          setRecord(false);
        }}
        mask={false}
        bodyStyle={{
          display: "flex",
          flexDirection: "column",
          height: "9rem",
          borderRadius: "1rem 1rem 0 0",
          boxShadow: "1px 1px 4px rgba(0, 0, 0, 0.3)",
          width: "90vw",
          marginLeft: "5vw",
        }}
      >
        <div className="pop-header">
          {ready ? (
            <div onClick={() => setPopVisible(false)}>返回</div>
          ) : (
            <>
              <div onClick={() => handleReLearn(record)}>
                {record ? "录音中" : "重新跟学"}
              </div>
              <div onClick={() => handleSubmit(record)}>
                {record ? <Timer /> : "提交"}
              </div>
            </>
          )}
        </div>
        <div className="pop-wrap">
          {loading && <SpinLoading color="#e3221c" />}
          {!loading && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                backgroundColor: "#ffffff",
              }}
            >
              <SoundWave size={"large"} active={record || playing} />
              {ready ? (
                <CusIcons
                  style={{
                    width: "4rem",
                    height: "4rem",
                    marginLeft: "0.4rem",
                    marginRight: "0.4rem",
                  }}
                  onClick={() => {
                    setReady(false);
                    startRecording();
                  }}
                />
              ) : (
                <img
                  style={{
                    width: "4rem",
                    height: "4rem",
                  }}
                  onClick={() => handleToggle(record)}
                  src={record ? IconStop : playing ? IconPause : IconPlay}
                  alt="link"
                />
              )}
              <SoundWave size={"large"} active={record || playing} />
            </div>
          )}
          <ReactMic
            mimeType="audio/mp3"
            record={record}
            className={"mic-hidden"}
            onStop={onStop}
            // onData={onData}
            strokeColor="#cecece"
            backgroundColor="#ffffff"
          />
        </div>
      </Popup>
    </div>
  );
}

export default TabOne;
