import React, { useState, useEffect, useRef } from "react";
import { Modal, Divider, Image, Ellipsis } from "antd-mobile";
import { EditFill, RedoOutline } from "antd-mobile-icons";
import PageHeader from "@/components/PageHeader";
import instance from "@/utils/request";
import { useSpring, animated } from "@react-spring/web";
import AddWordsModal from "./AddWordsModal";
import "./index.css";

const PAGE_SIZE = 10;

function MyPanel() {
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);
  const timerRef = useRef(null);
  const [reStart, setReStart] = useState("");
  const pageIndex = useRef(1);

  const getList = async (page) => {
    setLoading(true);
    const response = await instance.get("/api/getHistorySpeaks", {
      params: {
        type: "我要说",
        pageSize: PAGE_SIZE,
        pageIndex: page,
      },
    });
    const append = response.data.data;
    const hasMore = append.length === PAGE_SIZE;
    if (append.length === 0) {
      return;
    }
    setList(append);
    pageIndex.current = hasMore ? page + 1 : 1;
    setLoading(false);
  };

  useEffect(() => {
    getList(1);
  }, []);

  useEffect(() => {
    timerRef.current = setInterval(() => {
      getList(pageIndex.current);
    }, 1000 * 5);
    return () => clearInterval(timerRef.current);
  }, [reStart]);

  const renderExtra = () => {
    return (
      <div
        style={{ color: "#ffffff" }}
        onClick={() => {
          setReStart(Math.random);
          getList(pageIndex.current);
        }}
      >
        <RedoOutline />
        <span style={{ fontFamily: "FZXBSJW" }}>&nbsp;换一换</span>
      </div>
    );
  };

  return (
    <>
      <PageHeader title={"话感悟"} extra={renderExtra()} />
      <div className="my_panel_wrap">
        <div className="my_panel_list">
          {list.map((item, index) => {
            return <MyPanelItem key={item.id} index={index} data={item} />;
          })}
        </div>
        <div className="edit_wrap" onClick={() => setVisible(true)}>
          <EditFill
            fontSize={28}
            color="#ffffff"
            style={{ paddingBottom: "0.3rem" }}
          />
          <span
            style={{
              color: "#fff",
              fontWeight: "bold",
              fontSize: 10,
              fontFamily: "FZXBSJW",
            }}
          >
            写感悟
          </span>
        </div>

        <AddWordsModal
          visible={visible}
          onClose={(append) => {
            if (append) {
              const arr = list.concat();
              arr[0] = append;
              setList(arr);
            }
            setVisible(false);
          }}
        />
      </div>
    </>
  );
}

function MyPanelItem({ data, index }) {
  const renderDetail = (detail) => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      >
        <Image
          src={data.avatar_url}
          width={50}
          height={50}
          fit="cover"
          style={{
            borderRadius: 25,
          }}
        />
        <div
          style={{
            fontSize: "1.1rem",
            marginTop: "1rem",
          }}
        >
          {data.user_name}
        </div>
        <Divider style={{ width: "100%" }} />
        <div
          style={{
            fontSize: "1rem",
            lineHeight: "1.6rem",
            paddingLeft: "0.6rem",
            paddingRight: "0.6rem",
            height: "24rem",
            overflowY: "scroll",
            maxHeight: "24rem",
          }}
        >
          {detail.content}
        </div>
      </div>
    );
  };

  const handleViewDetail = (detail) => {
    Modal.show({
      content: renderDetail(detail),
      showCloseButton: true,
      closeOnMaskClick: true,
    });
  };

  const springs = useSpring({
    opacity: 1,
    from: { opacity: 0 },
    config: { duration: 1000 },
  });

  return (
    <animated.div
      style={{ ...springs }}
      className={`my_panel_card pos${index + 1} bg${data.label_id || 1}`}
      onClick={() => handleViewDetail(data)}
    >
      <Ellipsis direction="end" rows={5} content={data.content} />
    </animated.div>
  );
}

export default MyPanel;
