export function convertMillisecondsToMS(milliseconds) {
  var seconds = Math.floor(milliseconds / 1000);
  var minutes = Math.floor(seconds / 60);
  seconds = seconds % 60;

  var mmSS = ("0" + minutes).slice(-2) + ":" + ("0" + seconds).slice(-2);
  return mmSS;
}

export function convertMillisecondsToText(milliseconds) {
  var seconds = Math.floor(milliseconds / 1000);
  var minutes = Math.floor(seconds / 60);
  seconds = seconds % 60;

  var mmSS = ("0" + minutes).slice(-2) + "′" + ("0" + seconds).slice(-2) + "″";
  return mmSS;
}

export function getRandomOffsetX() {
  return -1 * (40 + Math.floor(Math.random() * 201));
}

export function getURLParams() {
  const searchParams = new URLSearchParams(window.location.search);
  const params = {};
  for (let pair of searchParams.entries()) {
    const [key, value] = pair;
    params[key] = value;
  }
  return params;
}
