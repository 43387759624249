import React, { useState } from "react";
import { Modal, Button, TextArea, ImageUploader, Toast } from "antd-mobile";
import instance from "@/utils/request";

export default function AddInfoModal(props) {
  const { visible, setVisible, userCode, onSuccess } = props;
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState("");
  const [fileList, setFileList] = useState([]);

  // 上传图片
  const handleUpload = async (file) => {
    const formData = new FormData();
    formData.append("fileToUpload", file);
    const response = await instance.post("/api/file/doUploadImg", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return {
      url: response.data.data.src,
    };
  };

  const handleSubmit = async () => {
    const params = {
      user_id: userCode,
      deed: value,
      picList: fileList,
    };
    setLoading(true);
    const res = await instance.post("/api/addDemeanour", params);
    if (res.data.code === 0) {
      setVisible(false);
      setLoading(false);
      Toast.show({
        icon: "success",
        content: "提交成功",
      });
      onSuccess(res.data.data);
    }
  };

  const renderInput = () => {
    return (
      <div className="my_textarea_wrap">
        <div style={{ backgroundColor: "#f4f4f4", padding: 6 }}>
          <TextArea
            style={{ backgroundColor: "#f4f4f4" }}
            placeholder="输入你想说的话"
            rows={4}
            autoSize
            showCount
            maxLength={50}
            onChange={(val) => {
              console.log("xx", val);
              setValue(val);
            }}
          />
        </div>
        <div
          style={{
            paddingTop: "1rem",
          }}
        >
          <ImageUploader
            value={fileList}
            onChange={setFileList}
            maxCount={3}
            style={{ "--cell-size": "70px" }}
            upload={handleUpload}
          />
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "1rem",
          }}
        >
          <Button
            block
            shape="rounded"
            color="primary"
            fill="outline"
            loading={loading}
            size="small"
            style={{
              fontFamily: "FZXBSJW",
              width: "6rem",
              marginRight: "1rem",
            }}
            onClick={() => {
              setFileList([]);
              setVisible(false);
            }}
          >
            返回
          </Button>
          <Button
            block
            shape="rounded"
            color="primary"
            size="small"
            loading={loading}
            onClick={handleSubmit}
            style={{ fontFamily: "FZXBSJW", width: "6rem" }}
          >
            提交
          </Button>
        </div>
      </div>
    );
  };

  return (
    <Modal
      visible={visible}
      content={renderInput()}
      closeOnMaskClick
      destroyOnClose
      onClose={() => {
        setVisible(false);
      }}
    />
  );
}
