import React, { useState } from "react";
import { Image } from "antd-mobile";
import { LikeOutline } from "antd-mobile-icons";
import instance from "@/utils/request";
import Slider from "react-slick";
import "./index.css";

function ShowCard(props) {
  const { data, cardType, userCode, squareAva } = props;
  const {
    name,
    pic,
    deed,
    info,
    like_num,
    id,
    picList,
    liked: likedData,
  } = data;
  const [liked, setLiked] = useState(Boolean(likedData));

  const handleLiked = async (flag) => {
    const apiUrl = flag ? "/api/likeDemeanour" : "/api/cancelLikeDemeanour";

    const res = await instance.get(apiUrl, {
      params: {
        userId: userCode,
        demeanourId: id,
      },
    });
    if (res) {
      setLiked(flag);
    }
  };

  return (
    <div className="shown-card-wrapper">
      <div className="card-wrapper-outer">
        <div className="card-wrapper-inner">
          {cardType === "1" && (
            <div
              className="header"
              style={{
                display: "block",
                height: "auto",
                paddingLeft: 0,
                paddingBottom: 8,
                backgroundSize: "100% auto",
              }}
            >
              <div
                style={{
                  width: "90%",
                  margin: "0 auto",
                  color: "#000",
                  fontSize: 18,
                  fontWeight: "bold",
                  paddingBottom: 8,
                }}
              >
                {info}
              </div>
              <img
                src={pic}
                alt="pic"
                style={{ width: "90%", aspectRatio: '16/9', margin: "0 auto", borderRadius: 4 }}
              />
            </div>
          )}

          {cardType === "3" && (
            <div className="header">
              <div style={{ display: "flex", alignItems: "center" }}>
                <div className={`avatar-wrap`}>
                  <Image
                    src={pic}
                    width={56}
                    height={56}
                    fit="cover"
                    style={{
                      borderRadius: squareAva ? 4 : 28,
                    }}
                  />
                </div>
                <div className="info-row1">{name}</div>
              </div>

              <div className="like-wrap">
                <LikeOutline
                  onClick={() => handleLiked(!liked)}
                  fontSize={26}
                  color={liked ? "#ffffff" : "#A1A1A1"}
                  style={{
                    backgroundColor: liked ? "#e3211b" : "ffffff",
                    padding: 8,
                    borderRadius: "50%",
                  }}
                />
                {console.log("likedData", likedData, likedData ? 1 : 0)}
                <div
                  style={{
                    color: "#A1A1A1",
                    marginTop: 4,
                    fontSize: "0.6rem",
                  }}
                >
                  {liked ? `${like_num + (likedData ? 0 : 1)}个赞` : "点赞"}
                </div>
              </div>
            </div>
          )}

          <div style={{ overflowY: "scroll", overflowX: "hidden" }}>
            {cardType === "3" && picList && picList.length > 0 && (
              <div>
                <Slider
                  autoplay={true}
                  dots={true}
                  arrows={false}
                  infinite={false}
                  className={"wcnm"}
                >
                  {picList.map((item) => (
                    <img key={item.id} src={item.url} alt="mar" />
                  ))}
                </Slider>
              </div>
            )}
            <div
              className="article"
              dangerouslySetInnerHTML={{ __html: deed }}
            ></div>
          </div>
          <div style={{ height: "1rem" }}></div>
        </div>
      </div>
    </div>
  );
}

export default ShowCard;
