import React, { useState, useEffect } from "react";
import ImageTab from "../ImageTab";
import instance from "@/utils/request";
import PageHeader from "@/components/PageHeader";
import { AppstoreOutline } from "antd-mobile-icons";
import Slider from "react-slick";
import { useGesture } from "react-use-gesture";
import Multiple from "./Multiple";
import useThrottle from "./useThrottle";
import "./index.css";

const PAGE_SIZE = 4;

function OthersPanel(props) {
  const [activeKey, setActiveKey] = useState(0);
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [pageIndex, setPageIndex] = useState(1);
  const [mulitple, setMulitple] = useState(false);

  const throttledActiveKey = useThrottle(activeKey, 200);

  const bind = useGesture({
    onDrag: ({ movement, direction }) => {
      if (Math.abs(movement[0]) >= 10) {
        if (direction[0] > 0) {
          // 后退
          if (throttledActiveKey === 0) {
            if (pageIndex === 2) return;
            getList(pageIndex - 2);
            setActiveKey(3);
          } else {
            setActiveKey(throttledActiveKey - 1);
          }
        } else {
          // 前进
          if (throttledActiveKey === list.length - 1) {
            if (!hasMore) return;
            getList(pageIndex);
            setActiveKey(0);
          } else {
            setActiveKey(throttledActiveKey + 1);
          }
        }
      }
    },
  });

  const onChange = (v) => {
    setActiveKey(v);
  };

  const getList = async (page) => {
    setLoading(true);
    const response = await instance.get("/api/getHistorySpeaks", {
      params: {
        type: "先锋说",
        pageSize: PAGE_SIZE,
        pageIndex: page,
        link_id: props.linkId,
      },
    });
    const append = response.data.data;
    if (append.length === 0) {
      setHasMore(false);
      return;
    }
    setList(append);
    setHasMore(append.length === PAGE_SIZE);
    setPageIndex(page + 1);
    setLoading(false);
  };

  const handleToggleMode = () => {
    setMulitple(!mulitple);
  };

  const renderExtra = () => {
    return (
      <div style={{ color: "#ffffff" }} onClick={handleToggleMode}>
        <AppstoreOutline />
        <span style={{ fontFamily: "FZXBSJW" }}>
          &nbsp;{mulitple ? "图文显示" : "列表显示"}
        </span>
      </div>
    );
  };

  useEffect(() => {
    getList(1);
  }, []);

  const currentItem = list[throttledActiveKey] || {};

  const userList =
    list.map((item) => {
      return {
        name: item.name,
        url: item.pic,
      };
    }) || [];

  return (
    <>
      <PageHeader title={"话感悟-先锋说"} extra={renderExtra()} />
      {mulitple ? (
        <Multiple />
      ) : (
        <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
          <ImageTab
            activeKey={throttledActiveKey}
            onChange={onChange}
            userList={userList}
          />
          <div className="contentx">
            {currentItem.picList && currentItem.picList.length > 0 && (
              <div>
                <Slider
                  autoplay={true}
                  dots={true}
                  arrows={false}
                  infinite={false}
                  className={"pic-road"}
                >
                  {currentItem.picList.map((item) => (
                    <img alt="x" key={item.id} src={item.url} />
                  ))}
                </Slider>
              </div>
            )}
            <div
              style={{
                textAlign: "center",
                paddingTop: "1rem",
                color: "#a1a1a1",
              }}
            >
              {currentItem.name} {currentItem.intro}
            </div>
            <div className="text" {...bind()}>
              {currentItem.content}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default OthersPanel;
