import React, { useState, useEffect } from "react";
import { Image, List, InfiniteScroll } from "antd-mobile";
import instance from "@/utils/request";
import PageHeader from "@/components/PageHeader";
import "./index.css";

const PAGE_SIZE = 10;

function TabThree(props) {
  const { viewDetail } = props;

  const [data, setData] = useState([]);
  const [pageIndex, setPageIndex] = useState(1);
  const [hasMore, setHasMore] = useState(false);

  const getList = async (page) => {
    const response = await instance.get("/api/getHistoryLearns", {
      params: {
        pageSize: PAGE_SIZE,
        pageIndex: page,
      },
    });
    const append = response.data.data;
    setData((prev) => prev.concat(append));
    setHasMore(append.length === PAGE_SIZE);
    setPageIndex(page + 1);
  };

  useEffect(() => {
    getList(1);
  }, []);

  const loadMore = () => {
    getList(pageIndex);
  };

  return (
    <>
      <PageHeader title={"学金句"} />

      <div className="learn_tab3_wrap">
        <List>
          {data.map((user) => (
            <List.Item
              arrow={false}
              key={user.id}
              onClick={() => viewDetail(user.id)}
              prefix={
                <Image
                  src={user.pic}
                  style={{ borderRadius: 25 }}
                  fit="cover"
                  width={50}
                  height={50}
                />
              }
            >
              <div className="list_item_wrap">
                <div className="list_item_name">{user.title}</div>
                <div className="list_item_row">
                  <div className="list_item_id">
                    <div>领学人：{user.name}</div>
                    <div>{user.job}</div>
                  </div>
                  <div className="list_item_date">
                    {user.publish_time && user.publish_time.substring(5, 10)}
                  </div>
                </div>
              </div>
            </List.Item>
          ))}
        </List>
        <InfiniteScroll loadMore={loadMore} hasMore={hasMore} />
        <div className="bottom-cover"></div>
      </div>
    </>
  );
}

export default TabThree;
