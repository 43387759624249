import React, { useState, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import { AutoCenter, SpinLoading } from "antd-mobile";
import PageHeader from "@/components/PageHeader";
import { useNavigate } from "react-router-dom";
import FixedTabs from "@/components/FixedTabs";
import ShowCard from "./ShowCard";
import ShowSelfList from "./ShowSelfList";
import Slider from "react-slick";
import instance from "@/utils/request";
import { AppContext } from "@/context";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./index.css";

const PAGE_SIZE = 10;

const tabs = [
  { key: "1", title: "先锋风采" },
  { key: "2", title: "展我风采" },
];

function ShowPage() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const linkId = searchParams.get("link_id");
  const navigate = useNavigate();
  const [activeKey, setActiveKey] = useState("1");
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [pageIndex, setPageIndex] = useState(1);
  const { userCode } = useContext(AppContext);

  const getList = async (page, type, reset) => {
    setLoading(true);
    const response = await instance.get("/api/getDemeanourList", {
      params: {
        type,
        pageSize: PAGE_SIZE,
        pageIndex: page,
        userId: userCode,
        link_id: linkId,
      },
    });
    const append = response.data.data;
    setList((prev) => (reset ? append : prev.concat(append)));
    setHasMore(append.length === PAGE_SIZE);
    setPageIndex(page + 1);
    setLoading(false);
  };

  useEffect(() => {
    getList(1, "先锋风采", true);
  }, [linkId]);

  const onTabChange = (key) => {
    if (key === "1") {
      getList(1, "先锋风采", true);
    }
    setActiveKey(key);
  };

  const handleAfterChange = (key) => {
    const activeTitle = tabs.find((tab) => tab.key === activeKey).title;
    if (key === list.length - 1 && hasMore) {
      getList(pageIndex, activeTitle);
    }
  };

  const settings = {
    infinite: false,
    centerMode: true,
    centerPadding: "36px",
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: handleAfterChange,
  };

  const renderTitle = () => {
    if (activeKey === "1") {
      return "展风采-先锋风采";
    }
    return "展风采";
  };

  return (
    <div className="show_wrap">
      <PageHeader title={renderTitle()} />

      {activeKey === "1" ? (
        <div className="content">
          <div className="card-wrapper-all">
            {loading ? (
              <AutoCenter>
                <SpinLoading color="#e3221c" />
              </AutoCenter>
            ) : (
              <Slider {...settings}>
                {list.map((item) => (
                  <ShowCard
                    key={item.id}
                    data={item}
                    cardType={"1"}
                    squareAva={true}
                  />
                ))}
              </Slider>
            )}
          </div>
        </div>
      ) : (
        <ShowSelfList userCode={userCode} />
      )}

      <div className="fixed-controls">
        <FixedTabs activeKey={activeKey} tabs={tabs} onChange={onTabChange} />
      </div>
    </div>
  );
}

export default ShowPage;
