import React, { useState, useEffect } from "react";
import { Button, Selector, Image } from "antd-mobile";
import bgWrong from "@/images/wrong.png";
import bgCorrect from "@/images/correct.png";
import "./index.css";

function ExamCard({ index, data, total, canAnswer, onModify, onSubmit }) {
  const { id, answerList, answerRecord, question, type } = data;
  const [select, setSelect] = useState([]);

  useEffect(() => {
    if (answerRecord && !canAnswer) {
      setSelect(answerRecord.user_answer_index);
    } else {
      setSelect([]);
    }
  }, [answerRecord, canAnswer]);

  const opts = answerList.map((item, index) => {
    const isCorrect = item.is_right === 1;
    return {
      label: item.answer,
      value: index,
      description:
        canAnswer === 0 && isCorrect ? (
          <span style={{ color: "#e3221c" }}>正确答案</span>
        ) : null,
    };
  });

  return (
    <div className="exam-card-wrapper">
      <div className="card-wrapper-outer">
        <div className="card-wrapper-inner" style={{ position: "relative" }}>
          {answerRecord && canAnswer === 0 && (
            <Image
              src={answerRecord.correct === 0 ? bgWrong : bgCorrect}
              width={"60%"}
              fit="cover"
              style={{
                position: "absolute",
                right: 0,
                bottom: 0,
              }}
            />
          )}
          <div className="header">
            <div className="examing">
              {canAnswer === 1 ? "答题进行中" : "答题已结束"}
            </div>
            {canAnswer === 1 ? (
              <div className="answered">
                已答题&nbsp;{index}/{total}
              </div>
            ) : (
              <div className="answered">
                第{index}/{total}题
              </div>
            )}
          </div>
          <div className="exam-article">
            <div style={{ width: "100%", paddingBottom: 24 }}>
              <div className="question-title">
                {index}、{question}
              </div>

              <Selector
                style={{
                  "--color": "#fff2f2",
                  "--checked-color": "#ff7571",
                  "--checked-text-color": "#fff",
                }}
                columns={1}
                options={opts}
                disabled={canAnswer === 0}
                value={select}
                multiple={type === "多选"}
                onChange={(arr) => {
                  setSelect(arr);
                  onModify(id, arr.join(","));
                }}
              />
            </div>
            {index === total && canAnswer !== 0 && (
              <Button
                block
                shape="rounded"
                color="primary"
                size="small"
                style={{ fontFamily: "FZXBSJW", width: "6rem" }}
                onClick={onSubmit}
              >
                提交
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ExamCard;
