/**
 * 悬浮tab组件
 */

import React from "react";
import { CapsuleTabs } from "antd-mobile";
import "./index.css";

function FixedTabs(props) {
  const { activeKey, onChange, tabs = [], size } = props;

  return (
    <div
      className={`fixed-tab-controls ${
        size === "small" && "fixed-tab-controls-small"
      }`}
    >
      <CapsuleTabs activeKey={activeKey} onChange={(key) => onChange(key)}>
        {tabs.map((tab) => (
          <CapsuleTabs.Tab title={tab.title} key={tab.key}></CapsuleTabs.Tab>
        ))}
      </CapsuleTabs>
    </div>
  );
}

export default FixedTabs;
