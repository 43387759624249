import React, { useState, useContext } from "react";
import { useLocation } from "react-router-dom";
import FixedTabs from "@/components/FixedTabs";
import OthersPanel from "./components/OthersPanel";
import MyPanel from "./components/MyPanel";
import { AppContext } from "@/context";
import "./index.css";

const tabs = [
  { key: "1", title: "先锋说" },
  { key: "2", title: "我要说" },
];

function SpeakPage() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const linkId = searchParams.get("link_id");
  const [activeKey, setActiveKey] = useState("1");
  const { userCode } = useContext(AppContext);

  const onTabChange = (key) => {
    setActiveKey(key);
  };

  return (
    <div className="speak_wrap">
      {activeKey === "1" && <OthersPanel userCode={userCode} linkId={linkId} />}
      {activeKey === "2" && <MyPanel userCode={userCode} />}
      <div className="fixed-controls">
        <FixedTabs activeKey={activeKey} tabs={tabs} onChange={onTabChange} />
      </div>
    </div>
  );
}

export default SpeakPage;
