import React, { useState } from "react";
import { Image, Ellipsis } from "antd-mobile";
import SoundPlayer from "@/components/SoundPlayer";
import "./index.css";

function HeaderBanner(props) {
  const {
    detailInfo = {},
    playID,
    currentPlayID,
    setCurrentPlayID,
    beforePlay,
  } = props;
  const { pic, audio, name, job, intro } = detailInfo;
  const [playingTopSound, setPlayingTopSound] = useState(true);

  const handleToggleTopSound = () => {
    if (beforePlay) {
      beforePlay();
    }
    setPlayingTopSound((prev) => !prev);
  };

  return (
    <div className="banner">
      <div className="banner-content">
        <div className={`avatar-wrap ${playingTopSound && "avatar-x"}`}>
          <Image
            src={pic}
            width={64}
            height={64}
            fit="cover"
            style={{ borderRadius: 32, borderWidth: 2, borderColor: "#fff" }}
          />
        </div>
        <div className="info-wrap">
          <div className="info-row1">
            <div className="nickname">{name}</div>
            <div className="job">{job}</div>
          </div>
          <div className="info-row2">
            <Ellipsis rows={2} direction="end" content={intro} />
          </div>
        </div>
      </div>
      <div className="words-wrap">
        {audio && (
          <SoundPlayer
            src={audio}
            onPlay={handleToggleTopSound}
            playID={playID}
            currentPlayID={currentPlayID}
            setCurrentPlayID={setCurrentPlayID}
          />
        )}
      </div>
    </div>
  );
}

export default HeaderBanner;
