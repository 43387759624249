import { useEffect, useState } from "react"
import { BrowserRouter, Routes, Route } from "react-router-dom"
import HomePage from "@/pages/Home"
import LearnPage from "@/pages/Learn"
import SpeakPage from "@/pages/Speak"
import ShowPage from "@/pages/Show"
import BookmarkPage from "@/pages/Bookmark"
import EntrancePage from "@/pages/Entrance"
import NotFound from "@/pages/NotFound"
import instance from "@/utils/request"
import { getURLParams } from "@/utils"
import { AppContext } from "./context"
import wx from "weixin-js-sdk"
import IconShare from "@/images/share-logo.png"

const API_BASE_URL = "https://xyzz.kv-you.com"
const WX_APP_ID = "wx9bf0354181dbf464"

function App() {
  const [userCode, setUserCode] = useState("a9ae1b9897d84d61a9ef3d8f13df6f99")

  const getWxConfig = async () => {
    const res = await instance.get("/api/getMaxBookRemarkRank")
    const rankDesc = res.data.data.totalRank
    let _shareLink = window.location.origin
    let _shareImgUrl = window.location.origin + IconShare

    try {
      const res = await instance.post(
        `/wx/wx_js?url=${window.location.href}`,
        {},
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      wx.config({
        debug: false,
        jsApiList: ["updateAppMessageShareData", "updateTimelineShareData"],
        ...res.data,
      })
      wx.ready(() => {
        wx.updateAppMessageShareData({
          title: "习语谆谆",
          desc: `累计已有${rankDesc}人获得书签，快来参与答题，集齐4张还能线下兑换书签哦！`,
          link: _shareLink,
          imgUrl: _shareImgUrl,
          success: () => {
            console.log("updateAppMessageShareData准备好了")
          },
        })

        wx.updateTimelineShareData({
          title: `习语谆谆-累计已有${rankDesc}人获得书签，快来参与答题，集齐4张还能线下兑换书签哦！`,
          link: _shareLink,
          imgUrl: _shareImgUrl,
          success: () => console.log("updateTimelineShareData success"),
        })
      })
    } catch (error) {
      console.log("微信分享api报错", error)
    }
  }

  const auth = () => {
    let authUrl = API_BASE_URL + "/api/getWxtUserInfo"
    let url =
      "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" +
      WX_APP_ID +
      "&redirect_uri=" +
      encodeURIComponent(authUrl) +
      "&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect"
    window.location.replace(url)
  }

  useEffect(() => {
    // 初始化微信分享功能;
    getWxConfig()
    const urlParams = getURLParams()
    if (urlParams && urlParams.user_code) {
      localStorage.setItem("user_code", urlParams.user_code)
      window.location.replace(window.location.origin)
    } else {
      const user_code = localStorage.getItem("user_code")
      if (user_code) {
        setUserCode(user_code)
      } else {
        auth()
      }
    }
  }, [])

  return (
    <AppContext.Provider value={{ userCode }}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/learn" element={<LearnPage />} />
          <Route path="/speak" element={<SpeakPage />} />
          <Route path="/show" element={<ShowPage />} />
          <Route path="/entrance" element={<EntrancePage />} />
          <Route path="/bookmark" element={<BookmarkPage />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </AppContext.Provider>
  )
}

export default App
